import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import BottomNavBarComponent from "../../component/bottom-nav-bar/bottom-nav-bar.component";
import LoadingComponent from "../../component/loading/loading.component";
import TaskWithActionComponent from "./components/task-with-action.component";
import Constants from "../../constants/constants";
import CoinService from "../../service/coin.service";
import {getLocalize} from "../../i18n-helper";

// const tg = window.Telegram.WebApp;

const EarnPage = () => {

    const userTelegramId = Constants.getTelegramUserId();//tg.initDataUnsafe.user?.id; // 226875807
    const { t, i18n } = useTranslation();

    const [tasksData, setTasksData] = useState(null);
    const [tasksDataIsLoading, setTasksDataIsLoading] = useState(false);
    const [tasksDataError, setTasksDataError] = useState(null);

    // const [successRewardAd, setSuccessRewardAd] = useState(false);
    // const [loadingAd, setLoadingAd] = useState(false);
    // const [errorAd, setErrorAd] = useState(null);

    useEffect(() => {
        getRewards()
    }, [])

    async function getRewards() {
        if (userTelegramId == null) {
            setTasksDataError(t('work_only_in_telegram_version'));
            return;
        }
        setTasksDataError(null);
        setTasksDataIsLoading(true)
        try {
            const tasksDataResponse = await CoinService.getTasks(userTelegramId);
            if (tasksDataResponse.success) {
                setTasksData({
                    tasks: tasksDataResponse.tasks,
                    totalCoins: tasksDataResponse.totalCoins
                })
            } else {
                setTasksDataError(tasksDataResponse.error);
            }
        } catch (e) {
            setTasksDataError(e.message);
        }
        setTasksDataIsLoading(false)
    }

    function updateTasksData(task) {
        tasksData.tasks.forEach((e) => {
            if (e.id === task.id) {
                e.success = task.success;
                e.error = task.error;
                e.isLoading = task.isLoading;
                e.isTouched = task.isTouched;
            }
        })
        if (task.success === true) {
            setTasksData({tasks: tasksData.tasks, totalCoins: tasksData.totalCoins + 1})
        } else {
            setTasksData({...tasksData, tasks: tasksData.tasks})
        }
    }

    function startAdsgram(task) {
        const adController = window.Adsgram?.init({
            blockId: Constants.adsgramBlockId,
            debug: Constants.adsgramBlockDebug,
        });

        if (adController) {
            adController.show()
                .then(() => {
                    // user watch ad till the end
                    task.success = true;
                    task.error = null
                    task.meta.dailyRewardCount += 1;
                    updateTasksData(task);
                })
                .catch((result) => {
                    // user get error during playing ad or skip ad
                    task.success = null;
                    task.error = result.description;
                    updateTasksData(task);
                });
        } else {
            task.success = null;
            task.error = t('adsgram_script_not_loaded');
            updateTasksData(task);
        }
    }
    
    async function taskTappedHandle(task) {
        if (task.status === "FINISHED") {
            return
        }

        switch (task.type) {
            case 'adsgram':
                await showAd(task);
                break;
            case 'tg_channel':
                const tgChannelLink = 'https://t.me/' + task.meta.link;
                await taskTappedHandleLink(task, tgChannelLink);
                break;
            case 'link':
                const link = task.meta.link;
                await taskTappedHandleLink(task, link);
                break;
        }
    }

    async function taskTappedHandleLink(task, link) {
        if (task.isTouched) {
            task.isLoading = true;
            task.error = null;
            task.success = null;
            updateTasksData(task);
            try {
                const verifyTaskResponse = await CoinService.verifyTask(userTelegramId, task.id);

                if (verifyTaskResponse.success) {
                    task.status = "FINISHED";
                } else {
                    if (verifyTaskResponse.error === 'Bad Request: PARTICIPANT_ID_INVALID') {
                        task.error = t('subscribe_error');
                    } else {
                        task.error = verifyTaskResponse.error;
                    }
                }
            } catch (e) {
                task.error = e.message;
            }
            task.isLoading = false;
            updateTasksData(task);
            return;
        }
        window.open(link)
        task.isTouched = true;
        updateTasksData(task);
    }

    async function showAd(task) {
        if (task.isLoading) {
            return;
        } else if (task.meta.dailyRewardCount >= 20) {
            task.error = t('you_watch_max_count_today');
            updateTasksData(task);
            return;
        }

        try {
            if (userTelegramId == null) {
                task.error = t('work_only_in_telegram_version');
                updateTasksData(task);
                return;
            }

            // setLoadingAd(true);
            // const rewardDataResponse = await CoinService.getTasks(userTelegramId);
            //
            // if (!rewardDataResponse.success) {
            //     throw new Error(rewardDataResponse.error)
            // }
            // setLoadingAd(false);

            startAdsgram(task);

        } catch (e) {
            task.isLoading = false;
            task.error = e.message;
            updateTasksData(task);
        }
    }

    function formattedTask() {
        let formattedTask = [];

        for (const e of tasksData.tasks) {
            if (formattedTask[e.category] == null) {
                formattedTask[e.category] = [e]
            } else {
                formattedTask[e.category].push(e)
            }
        }
        return formattedTask;
    }

    return (
        <div className={'page'}>
            <div className={'page-container'}>
                <div className={'mb-10'}>
                    <div className={'title mb-5'}>{t('earn')}:</div>
                    <LoadingComponent isLoading={tasksDataIsLoading} error={tasksDataError}/>

                    {tasksData
                        ? <>
                            <div>{t('you_earn')} {tasksData.totalCoins ?? 0} $BUBBLE</div>
                            {/*<div className={'mb-20 content-settings-section-earn-text'}>*/}
                            {/*    <a style={{color: '#ffffff90'}}*/}
                            {/*        href={'https://truthful-cheddar-de7.notion.site/Coin-Bubbles-11ea6ccfa2d48069be54e7b5c92e0390'}*/}
                            {/*        target={'_blank'}*/}
                            {/*    >{t('airdrop_description')}*/}
                            {/*    </a>*/}
                            {/*</div>*/}

                            {Object.entries(formattedTask()).map((task, taskIndex) =>
                                <div key={taskIndex + 1} className={'mb-10'}>
                                    <div className={'mb-5'}>{getLocalize(i18n, task[0])}</div>

                                    {task[1].map((subTask, index) =>
                                        <div key={index + 1} style={{marginBottom: '6px'}}>
                                            <TaskWithActionComponent
                                                subTask={subTask}
                                                taskTappedAction={taskTappedHandle}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                        : null
                    }

                </div>
            </div>

            <BottomNavBarComponent activeLink={3}/>
        </div>
    );
}

export default EarnPage;
