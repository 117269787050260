import React, {useEffect, useRef, useState} from "react";
import CoinService from "../../service/coin.service";
import Modal from "../../modal/modal";
import {RagePage} from "../../constants/constants";
import ChartPage from "../chart/chart.page";
import {AppType, Period} from "../../helper/models";
import {isCollision, resolveCollision} from './bubbleUtils';
import ContentSettingsPage from "../content-settings-page/content-settings.page";
import BottomNavBarComponent from "../../component/bottom-nav-bar/bottom-nav-bar.component";
import { useTranslation } from "react-i18next";
import SearchCoinPage from "../search-coin/search-coin.page";

const tg = window.Telegram.WebApp;

const CryptocurrenciesPage = () => {
    const [userData, setUserData] = useState(null);
    const [onInitReferResponseError, setOnInitReferResponseError] = useState(null);

    const [appType, setAppType] = useState(AppType.CMC);
    const [period, setPeriod] = useState(Period.DAY);

    const [isCoinListLoading, setIsCoinListLoading] = useState(false);
    const [coinList, setCoinList] = useState([]);
    const [error, setError] = useState(null);

    const [coinImages, setCoinImages] = useState([]);
    // {coinId: null, imageData: null}

    const [currentPage, setCurrentPage] = useState(1);

    const [selectedCoin, setSelectedCoin] = useState(null);
    const [currency, setCurrency] = useState("USD");

    const [contentSettingPageIsDisplayed, setContentSettingPageIsDisplayed] = useState(false);
    const [contentSearchPageIsDisplayed, setContentSearchPageIsDisplayed] = useState(false);

    const svgRef = useRef(null);
    const topDivRef = useRef(null);
    const bottomDivRef = useRef(null);
    const requestAnimationFrameRef = useRef();
    const [bubbles, setBubbles] = useState([]);

    const { t } = useTranslation();
    const MIN_SPEED = 0.01;
    const FRICTION = 0.99;

    useEffect(() => {
        const svg = svgRef.current;
        const topDiv = topDivRef.current;
        const bottomDiv = bottomDivRef.current;

        if (svg && topDiv) {
            console.log('bottomDiv = ',bottomDiv)
            svg.setAttribute('width', window.innerWidth);
            svg.setAttribute('height', window.innerHeight - topDiv.offsetHeight - 40 - 20 - 2);
        } else {
            console.log('updateSvgSize svg null');
        }
    }, []);

    useEffect(() => {
        if (tg.platform === 'ios' || tg.platform === 'android' || tg.platform === 'tdesktop' || tg.platform === 'macos') {
            const userTelegramId = tg.initDataUnsafe.user?.id;
            const startParam = tg.initDataUnsafe.start_param;
            const username = tg.initDataUnsafe.user?.username ?? tg.initDataUnsafe.user?.firstName ?? 'unowned name';
            if (userTelegramId != null) {
                setUserData({
                    platform: tg.platform,
                    userTelegramId: userTelegramId,
                    username: username,
                    startParam: startParam
                });

                onInitUser(userTelegramId, username, startParam)

            } else {
                // todo add error !!!
            }
        } else {
            setUserData({
                platform: tg.platform
            });
        }
    }, []);

    useEffect(() => {
        if (userData == null) {return}
        getCoinList();
    }, [userData, appType]);

    useEffect(() => {
        if (coinList.length === 0 || isCoinListLoading) return;

        const svg = svgRef.current;

        if (!svg) {
            setError('svg not init');
            return;
        }

        const coinListCurrentPage = coinList.slice(RagePage[currentPage].start-1, RagePage[currentPage].end-1)

        const svgWidth = svg.clientWidth;
        const svgHeight = svg.clientHeight;
        const targetArea = svgWidth * svgHeight * 0.65;

        const maxValue = Math.max(...coinListCurrentPage.map((value) => Math.abs(getChangeValueForMax(value))));
        let minValue = Math.min(...coinListCurrentPage.map((value) => Math.abs(getChangeValueForMax(value))));
        const normalizedArray1 = coinListCurrentPage.map(value => {
            const normized = (Math.abs(getChangeValueForMax(value)) - minValue) / (maxValue - minValue);

            // Применим экспоненциальную функцию для усиления малых значений и сглаживания больших
            const adjustedNormalized = Math.pow(normized, 0.7); // Используем квадратный корень
            // value.normized = adjustedNormalized// > 0.01 ? normized : normized*100
            // value.normized = adjustedNormalized// > 0.01 ? normized : normized*100

            // value.normized = normized// > 0.01 ? normized : normized*100


            value.normized = adjustedNormalized;

            return value;
        });
        const normalizedArrayMaxValue = Math.max(...normalizedArray1.map((value) => value.normized));
        const normalizedArray = normalizedArray1.map(value => {
            value.normized = Math.pow(value.normized, normalizedArrayMaxValue);
            return value;
        });
        const totalValue = normalizedArray.reduce((sum, value) => sum + value.normized, 0);

        const calculateBubbleRadius = (normalizedValue, scaleFactor) => {
            const bubbleArea = normalizedValue * scaleFactor;
            return Math.sqrt(bubbleArea / Math.PI);
        };

        const getRandomPosition = (radius) => ({
            x: Math.random() * (svgWidth - radius * 2) + radius,
            y: Math.random() * (svgHeight - radius * 2) + radius
        });

        const generateBubbles = () => {
            let scaleFactor = targetArea / totalValue;
            const newBubbles = [];

            for (const value of normalizedArray) {
                let radius;
                let position;
                // let hasCollision;

                // do {
                    radius = calculateBubbleRadius(value.normized, scaleFactor);
                    position = getRandomPosition(radius);
                    // hasCollision = false;

                    // for (const bubble of newBubbles) {
                    //     if (isCollision(position, bubble.position, radius, bubble.radius)) {
                    //         hasCollision = true;
                    //         scaleFactor *= 0.999;
                    //         break;
                    //     }
                    // }
                // } while (hasCollision);

                const speed = {
                    x: (Math.random() - 0.5) * 2,
                    y: (Math.random() - 0.5) * 2
                };

                const coin = {
                    normized: value.normized,
                    id: value.id,
                    symbol: value.symbol,
                    image: value.image,
                    imageValue: null,
                    imageAlreadyDownloaded: false,
                    change: getChangeValue(value),
                    position: position,
                    radius: radius,
                    speed: speed,
                    targetRadius: radius,
                    performance: value.performance,
                    marketcap: value.marketcap,
                    volume: value.volume,
                    price: value.price,
                    address: value.address,
                    rank: value.rank,
                    cg_id: value.cg_id,
                    cmc_id: value.cmc_id,
                }
                newBubbles.push(coin);
            }

            setBubbles(newBubbles);
        };

        generateBubbles();
    }, [coinList, period, currentPage]);

    useEffect(() => {
        if (bubbles.length === 0) return;

        // downloadImages()

        const updateAndDrawBubbles = () => {
            const svg = svgRef.current;
            if (!svg) return;

            svg.innerHTML = ''; // Очищаем содержимое SVG
            const svgWidth = svg.clientWidth;
            const svgHeight = svg.clientHeight;

            createGradients(svg)

            bubbles.forEach( async (bubble, i) => {
                for (let j = i + 1; j < bubbles.length; j++) {
                    const otherBubble = bubbles[j];
                    if (isCollision(bubble.position, otherBubble.position, bubble.radius, otherBubble.radius)) {
                        resolveCollision(bubble, otherBubble, svgWidth, svgHeight);
                    }
                }

                bubble.speed.x *= FRICTION;
                bubble.speed.y *= FRICTION;

                if (Math.abs(bubble.speed.x) < MIN_SPEED) bubble.speed.x = Math.sign(bubble.speed.x) * MIN_SPEED;
                if (Math.abs(bubble.speed.y) < MIN_SPEED) bubble.speed.y = Math.sign(bubble.speed.y) * MIN_SPEED;

                bubble.position.x += bubble.speed.x;
                bubble.position.y += bubble.speed.y;

                if (bubble.position.x - bubble.radius < 0 || bubble.position.x + bubble.radius > svg.clientWidth) {
                    bubble.speed.x *= -1;
                }
                if (bubble.position.y - bubble.radius < 0 || bubble.position.y + bubble.radius > svg.clientHeight) {
                    bubble.speed.y *= -1;
                }

                const bubbleGroup = document.createElementNS("http://www.w3.org/2000/svg", "g");
                bubbleGroup.setAttribute("style", "pointer-events: auto; cursor: pointer;");

                const bubbleGradient = bubble.change > 0 ? 'url(#green-gradient)' : 'url(#red-gradient)';

                const bubbleCircle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
                bubbleCircle.setAttribute("cx", bubble.position.x);
                bubbleCircle.setAttribute("cy", bubble.position.y);
                bubbleCircle.setAttribute("r", bubble.radius);
                bubbleCircle.setAttribute("fill", bubbleGradient);  // Применяем градиент
                bubbleCircle.setAttribute("style", "pointer-events: none;");
                bubbleGroup.appendChild(bubbleCircle);

                // if (bubble.imageValue != null) {
                //     console.log('bubble.imageValue = ', bubble.imageValue != null)
                //     const imageWidth = bubble.radius / 1.7
                //     const imageX = bubble.position.x - imageWidth / 2;
                //     const imageY = bubble.position.y - bubble.radius / 1.15;
                //     const image = document.createElementNS("http://www.w3.org/2000/svg", "image");
                //     // const imageurl = Constants.imageBaseUrl + `?providerType=${appType}&coinId=${bubble.id}`// + bubble.image;
                //     // console.log('bubble.imageValue = ', bubble.imageValue)
                //     // const bimage = getImage(bubble.id);
                //     image.setAttribute("href", bubble.imageValue);
                //     image.setAttribute("x", imageX);
                //     image.setAttribute("y", imageY);
                //     image.setAttribute("width", imageWidth);
                //     image.setAttribute("height", imageWidth);
                //     image.setAttribute("style", "pointer-events: none;");
                //     bubbleGroup.appendChild(image);
                // }

                const symbolFontSize = bubble.radius / 1.7
                const textSymbol = document.createElementNS("http://www.w3.org/2000/svg", "text");
                textSymbol.setAttribute("font-size", symbolFontSize);
                textSymbol.textContent = bubble.symbol;
                const textSymbolLength = textSymbol.getComputedTextLength();
                const symbolX = bubble.position.x - textSymbolLength / 2;
                textSymbol.setAttribute("x", symbolX);
                // textSymbol.setAttribute("y", bubble.position.y + bubble.radius / 4);
                textSymbol.setAttribute("y", bubble.position.y + bubble.radius / 7);
                textSymbol.setAttribute("text-anchor", "middle");
                textSymbol.setAttribute("fill", "white");
                textSymbol.setAttribute("style", "pointer-events: none;");
                bubbleGroup.appendChild(textSymbol);

                const changeFontSize = bubble.radius / 2.9;
                const textChange = document.createElementNS("http://www.w3.org/2000/svg", "text");
                textChange.setAttribute("font-size", changeFontSize);
                textChange.textContent = `${bubble.change}%`;
                const textChangeLength = textSymbol.getComputedTextLength();
                textChange.setAttribute("x", bubble.position.x - textChangeLength / 1.5);
                // textChange.setAttribute("y", bubble.position.y + bubble.radius / 1.5);
                textChange.setAttribute("y", bubble.position.y + bubble.radius / 1.8);
                textChange.setAttribute("text-anchor", "middle");
                textChange.setAttribute("fill", "white");
                textChange.setAttribute("style", "pointer-events: none;");
                bubbleGroup.appendChild(textChange);

                svg.appendChild(bubbleGroup);
            });

            requestAnimationFrameRef.current = requestAnimationFrame(updateAndDrawBubbles);
        };

        updateAndDrawBubbles();

        return () => {
            cancelAnimationFrame(requestAnimationFrameRef.current);
        }
    }, [bubbles]);

    useEffect(() => {
        const svg = svgRef.current;

        const handleClick = (event) => {
            const rect = svg.getBoundingClientRect();
            const mouseX = event.clientX - rect.left;
            const mouseY = event.clientY - rect.top;
            bubbles.forEach((bubble) => {
                const dx = mouseX - bubble.position.x;
                const dy = mouseY - bubble.position.y;
                const distance = Math.sqrt(dx * dx + dy * dy);
                if (distance < bubble.radius) {
                    setSelectedCoin(bubble)
                }
            });
        };
        const handleMouseMove = (event) => {
            console.log('asd')
            const rect = svg.getBoundingClientRect();
            const mouseX = event.clientX - rect.left;
            const mouseY = event.clientY - rect.top;
            let cursorStyle = 'default';

            bubbles.forEach((bubble) => {
                const dx = mouseX - bubble.position.x;
                const dy = mouseY - bubble.position.y;
                const distance = Math.sqrt(dx * dx + dy * dy);

                if (distance < bubble.radius) {
                    cursorStyle = 'pointer';
                }
            });

            svg.style.cursor = cursorStyle;
        };

        svg.addEventListener('click', handleClick);
        svg.addEventListener('mousemove', handleMouseMove);

        return () => {
            svg.removeEventListener('click', handleClick);
            svg.removeEventListener('mousemove', handleMouseMove);
        };
    }, [bubbles]);

    async function onInitUser(userTelegramId, username, startParam) {
        try {
            const referralsData = await CoinService.onInitUser(userTelegramId, username, startParam);
            console.log('referralsData = ', referralsData)
        } catch (e) {
            setOnInitReferResponseError(e.message);
        }
    }

    function getChangeValue(coin) {
        switch (period) {
            case Period.HOUR:
                return coin.performance.hour ?? 0.0001;
            case Period.DAY:
                return coin.performance.day ?? 0.0001;
            case Period.WEEK:
                return coin.performance.week ?? 0.0001;
            case Period.MONTH:
                return coin.performance.month ?? 0.0001;
            case Period.YEAR:
                return coin.performance.year ?? 0.0001;
        }
    }

    function getChangeValueForMax(coin) {
        let val;
        switch (period) {
            case Period.HOUR:
                val = coin.performance.hour ?? 0.0001;
                break;
            case Period.DAY:
                val = coin.performance.day ?? 0.0001;
                break;
            case Period.WEEK:
                val = coin.performance.week ?? 0.0001;
                break;
            case Period.MONTH:
                val = coin.performance.month ?? 0.0001;
                break;
            case Period.YEAR:
                val = coin.performance.year ?? 0.0001;
                break
            default:
                val = 0.0001;
        }

        if (val > 500) {
            val = 500
        }

        return val;
    }

    async function getCoinList() {
        setIsCoinListLoading(true);
        try {
            const response = await CoinService.getCoinList(appType, userData.userTelegramId)

            if (!response.success) {
                setError(response.error);
            } else {
                let coinList = [];

                response.data.forEach((e) => {
                    coinList.push({
                        id: e.id,
                        image: e.image,
                        name: e.name,
                        symbol: e.symbol,
                        performance: e.performance,
                        marketcap: e.marketcap,
                        volume: e.volume,
                        price: e.price,
                        address: e.address,
                        rank: e.rank,
                        cg_id: e.cg_id,
                        cmc_id: e.cmc_id,
                    })
                });

                setCoinList(coinList)
            }

        } catch (e) {
            console.log(e)
            setError(e.message)
        }
        setIsCoinListLoading(false)
    }

    const createGradients = (svg) => {
        const defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");

        // Создаем зеленый градиент
        const greenGradient = document.createElementNS("http://www.w3.org/2000/svg", "radialGradient");
        greenGradient.setAttribute("id", "green-gradient");
        greenGradient.setAttribute("cx", "50%");
        greenGradient.setAttribute("cy", "50%");
        greenGradient.setAttribute("r", "50%");
        greenGradient.setAttribute("fx", "50%");
        greenGradient.setAttribute("fy", "50%");

        const greenStop1 = document.createElementNS("http://www.w3.org/2000/svg", "stop");
        greenStop1.setAttribute("offset", "60%");
        greenStop1.setAttribute("stop-color", "rgba(0, 0, 3, 0)");

        const greenStop2 = document.createElementNS("http://www.w3.org/2000/svg", "stop");
        greenStop2.setAttribute("offset", "100%");
        greenStop2.setAttribute("stop-color", "rgba(0, 203, 0, 1)");

        greenGradient.appendChild(greenStop1);
        greenGradient.appendChild(greenStop2);

        // Создаем красный градиент
        const redGradient = document.createElementNS("http://www.w3.org/2000/svg", "radialGradient");
        redGradient.setAttribute("id", "red-gradient");
        redGradient.setAttribute("cx", "50%");
        redGradient.setAttribute("cy", "50%");
        redGradient.setAttribute("r", "50%");
        redGradient.setAttribute("fx", "50%");
        redGradient.setAttribute("fy", "50%");

        const redStop1 = document.createElementNS("http://www.w3.org/2000/svg", "stop");
        redStop1.setAttribute("offset", "60%");
        redStop1.setAttribute("stop-color", "rgba(0, 0, 3, 0)");

        const redStop2 = document.createElementNS("http://www.w3.org/2000/svg", "stop");
        redStop2.setAttribute("offset", "100%");
        redStop2.setAttribute("stop-color", "#f66");

        redGradient.appendChild(redStop1);
        redGradient.appendChild(redStop2);

        // Добавляем градиенты в элемент defs
        defs.appendChild(greenGradient);
        defs.appendChild(redGradient);
        svg.appendChild(defs);
    };

    // async function getImage(coinId) {
    //     // const coinId = 4558;
    //     if (coinId == null) { return }
    //
    //     let image =  null;
    //
    //     for (let i = 0; i < coinImages.length; i++) {
    //         const tempCoinImage = coinImages[i];
    //         if (tempCoinImage.coinId == coinId) {
    //             image = tempCoinImage.imageData;
    //         }
    //     }
    //
    //     if (image == null) {
    //         const response = await CoinService.loadImage(appType, coinId);
    //         const ima = {
    //             coinId: coinId,
    //             imageData: response
    //         };
    //         setCoinImages([...coinImages, ima]);
    //
    //         image = response;
    //     }
    //
    //     return image;
    // }

    async function downloadImages() {
        if (bubbles.length === 0) return;

        for (let i = 0; i < bubbles.length; i++) {
            if (bubbles[i].imageValue == null && bubbles[i].imageAlreadyDownloaded === false) {
                bubbles[i].imageAlreadyDownloaded = true;
            // if (bubbles[i].imageValue == null) {

                // console.log('bubbles[i].symbol = ', bubbles[i].symbol)

                let coinId = bubbles[i].id;
                if (appType === AppType.DYOR) {
                    coinId = bubbles[i].address
                }

                // const response = await CoinService.loadImage(appType, coinId);
                // console.log(`symbol = ${bubbles[i].symbol}; response = ${response}`)
                // bubbles[i].imageValue = response;

                CoinService.loadImage(appType, coinId).then((e) => {
                    if (e != null) {
                        bubbles[i].imageValue = e;
                    }
                })
            }
        }
    }

    function changeAppType(newAppType) {
        if (appType === newAppType) {
            return
        }

        const svg = svgRef.current;
        svg.innerHTML = ''; // Очищаем содержимое SVG
        setBubbles([]);

        if (period === Period.HOUR || period === Period.YEAR) {
            setPeriod(Period.DAY)
        }

        setCurrentPage(1)

        switch (appType) {
            case AppType.CMC:
                setAppType(AppType.DYOR);
                // setCurrency("TON");
                break;
            case AppType.DYOR:
                setAppType(AppType.CMC);
                // setCurrency("USD");
                break;
        }
    }
    
    function changeCurrentPage(page) {
        if (appType == AppType.DYOR) {
            return;
        }
        setCurrentPage(page);
    }

    function updatePeriodAction(period) {
        if (isCoinListLoading) {
            return;
        }
        setPeriod(period);
    }

    function showSettingPageAction() {
        if (isCoinListLoading) {
            return;
        }
        setContentSettingPageIsDisplayed(!contentSettingPageIsDisplayed);
        setContentSearchPageIsDisplayed(false);
        setSelectedCoin(null)
    }

    function onSettingsOnClickHandle() {
        showSettingPageAction()
    }

    function onSearchOnClickHandle() {
        showSearchPageAction()
    }

    function showSearchPageAction() {
        if (isCoinListLoading) {
            return;
        }
        setContentSearchPageIsDisplayed(!contentSearchPageIsDisplayed);
        setContentSettingPageIsDisplayed(false);
        setSelectedCoin(null)
    }

    function searchCoinPageOnTappedCoin(coin) {
        setContentSettingPageIsDisplayed(false);
        setContentSearchPageIsDisplayed(false);
        setSelectedCoin(coin)
    }

    return (
        <div className={'page'}>
            {isCoinListLoading || error
                ? <div style={{
                    position: 'absolute',
                    display: 'flex',
                    height: '80%',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {isCoinListLoading
                        ? <div><span>{t('loading')}...</span></div>
                        : null
                    }

                    {error
                        ? <div><span>{t('error')}: {error}</span></div>
                        : null
                    }
                </div>
                : null
            }

            {selectedCoin != null
                ? <Modal visible={selectedCoin != null} setVisible={setSelectedCoin}>
                    <ChartPage setVisible={setSelectedCoin} appType={appType} coinData={selectedCoin}
                               period={period} currency={currency}/>
                </Modal>
                : null
            }

            {contentSettingPageIsDisplayed
                ? <Modal setVisible={() => {
                    setContentSettingPageIsDisplayed(false)
                }}>
                    <ContentSettingsPage
                        appType={appType}
                        onChangeAppType={changeAppType}
                        period={period}
                        setPeriodOnClick={updatePeriodAction}
                        setVisible={setContentSettingPageIsDisplayed}
                        currentPage={currentPage}
                        setPage={changeCurrentPage}
                        userTelegramId={userData.userTelegramId}
                        username={userData.username}
                        startParam={userData.startParam}
                        onInitReferResponseError={onInitReferResponseError}
                    />
                </Modal>
                : null
            }

            {contentSearchPageIsDisplayed
                ? <Modal setVisible={() => {
                    setContentSearchPageIsDisplayed(false)
                }}>
                    <SearchCoinPage
                        coinData={coinList}
                        setVisible={setContentSearchPageIsDisplayed}
                        onTappedCoin={searchCoinPageOnTappedCoin}
                    />
                </Modal>
                : null
            }

            <div
                ref={topDivRef}
                style={{
                    // padding: '5px 0 5px 10px',
                    // display: 'flex',
            }}>
                {/*<div*/}
                {/*    className={`${isCoinListLoading ? 'opacity-05' : 'opacity-1'}`}*/}
                {/*    style={{*/}
                {/*        display: 'flex',*/}
                {/*        overflow: 'overlay',*/}
                {/*    }}>*/}
                {/*    <button*/}
                {/*        className={`period-button cursor-pointer`}*/}
                {/*        style={{padding: '0 7px'}}*/}
                {/*        onClick={() => changeAppType()}>*/}
                {/*        <img height={30} width={30} src={getAppTypeSymbol()}/>*/}
                {/*    </button>*/}
                {/*    {appType === AppType.CMC*/}
                {/*        ? <button*/}
                {/*            className={`period-button cursor-pointer ${period === Period.HOUR ? 'blue' : 'white-12'}`}*/}
                {/*            onClick={() => updatePeriodAction(Period.HOUR)}>hour*/}
                {/*        </button>*/}
                {/*        : null*/}
                {/*    }*/}
                {/*    <button*/}
                {/*        className={`period-button cursor-pointer ${period === Period.DAY ? 'blue' : 'white-12'}`}*/}
                {/*        onClick={() => updatePeriodAction(Period.DAY)}>day*/}
                {/*    </button>*/}
                {/*    <button*/}
                {/*        className={`period-button cursor-pointer ${period === Period.WEEK ? 'blue' : 'white-12'}`}*/}
                {/*        onClick={() => updatePeriodAction(Period.WEEK)}>week*/}
                {/*    </button>*/}
                {/*    <button*/}
                {/*        className={`period-button cursor-pointer ${period === Period.MONTH ? 'blue' : 'white-12'}`}*/}
                {/*        onClick={() => updatePeriodAction(Period.MONTH)}>month*/}
                {/*    </button>*/}
                {/*    {appType === AppType.CMC*/}
                {/*        ? <button*/}
                {/*            className={`period-button cursor-pointer ${period === Period.YEAR ? 'blue' : 'white-12'}`}*/}
                {/*            onClick={() => updatePeriodAction(Period.YEAR)}>year*/}
                {/*        </button>*/}
                {/*        : null*/}
                {/*    }*/}
                {/*</div>*/}
                {/*<div style={{marginLeft: '3px'}}>*/}
                {/*    <button*/}
                {/*        className={`period-button cursor-pointer ${contentSettingPageIsDisplayed ? 'blue' : 'white-12'} ${isCoinListLoading ? 'opacity-05' : 'opacity-1'}`}*/}
                {/*        onClick={() => showSettingPageAction()}>*/}
                {/*        /!*{RagePage[currentPage].start}-{RagePage[currentPage].end}*!/*/}
                {/*        <svg style={{fill: 'currentColor', pointerEvents: 'none', height: '24px'}} viewBox="0 -960 960 960">*/}
                {/*            <path d="M120-200v-80h720v80H120Zm0-160v-80h720v80H120Zm0-160v-80h720v80H120Zm0-160v-80h720v80H120Z"></path>*/}
                {/*        </svg>*/}
                {/*    </button>*/}
                {/*</div>*/}
            </div>

            <svg ref={svgRef}/>

            <BottomNavBarComponent
                ref={bottomDivRef}
                activeLink={1}
                onSettingsOnClick={onSettingsOnClickHandle}
                onSearchOnClick={onSearchOnClickHandle}
            />
        </div>
    );
};


export default CryptocurrenciesPage;
