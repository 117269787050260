import React, {useState} from 'react';
import {Constants, RagePage} from "../../constants/constants";
import {AppType, Period} from "../../helper/models";
import { useTranslation } from "react-i18next";

const tg = window.Telegram.WebApp;

const ContentSettingsPage = ({
                                 appType,
                                 onChangeAppType,
                                 period,
                                 setPeriodOnClick,
                                 setVisible,
                                 currentPage,
                                 setPage,
                                 userTelegramId,
                                 username,
                                 startParam,
                                 onInitReferResponseError,
}) => {
    const { t, i18n } = useTranslation();

    function changeLang(newLang) {
        if (newLang === "ru") {
            i18n.changeLanguage('ru');
        } else {
            i18n.changeLanguage('en');
        }
    }

    return (
        <div style={{
            padding: '10px'
        }}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center'}}>
                <div style={{display: 'flex'}}>
                    {/*<div style={{marginLeft: '10px'}}><span>{coinData.symbol}</span></div>*/}
                </div>
                <button className={'modal-close'} onClick={() => setVisible(false)}>
                    <svg style={{height: '24px', fill: 'white'}} viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </svg>
                </button>
            </div>

            <div className={'mb-10'}>
                {/*<div className={'section-title'}>{t('lang')}:</div>*/}
                <div style={{marginTop: '5px', display: 'flex'}}>
                    <button style={{padding: '0px 10px'}} className={`period-button cursor-pointer ${i18n.resolvedLanguage == "ru" ? 'blue' : 'white-12'}`} onClick={() => changeLang('ru')}>ru</button>
                    <button style={{padding: '0px 10px'}} className={`period-button cursor-pointer ${i18n.resolvedLanguage == "en" ? 'blue' : 'white-12'}`} onClick={() => changeLang('en')}>en</button>
                </div>
            </div>

            <div className={'mb-10'}>
                <div className={'section-title'}>{t('type')}:</div>
                <div style={{marginTop: '5px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    <button className={`period-button cursor-pointer ${appType == AppType.CMC ? 'blue' : 'white-12'}`} onClick={() => onChangeAppType(AppType.CMC)}>{t('coinmarketcap')}</button>
                    <button className={`period-button cursor-pointer ${appType == AppType.DYOR ? 'blue' : 'white-12'}`} onClick={() => onChangeAppType(AppType.DYOR)}>{t('ton')}</button>
                </div>
            </div>

            <div className={'mb-10'}>
                <div className={'section-title'}>{t('period')}:</div>
                <div style={{marginTop: '5px', display: 'flex', flexWrap: 'nowrap', overflowX: 'auto'}}>
                    {appType === AppType.CMC
                        ? <button
                            className={`period-button cursor-pointer ${period === Period.HOUR ? 'blue' : 'white-12'}`}
                            onClick={() => setPeriodOnClick(Period.HOUR)}>{t('hour')}
                        </button>
                        : null
                    }
                    <button
                        className={`period-button cursor-pointer ${period === Period.DAY ? 'blue' : 'white-12'}`}
                        onClick={() => setPeriodOnClick(Period.DAY)}>{t('day')}
                    </button>
                    <button
                        className={`period-button cursor-pointer ${period === Period.WEEK ? 'blue' : 'white-12'}`}
                        onClick={() => setPeriodOnClick(Period.WEEK)}>{t('week')}
                    </button>
                    <button
                        className={`period-button cursor-pointer ${period === Period.MONTH ? 'blue' : 'white-12'}`}
                        onClick={() => setPeriodOnClick(Period.MONTH)}>{t('month')}
                    </button>
                    {appType === AppType.CMC
                        ? <button
                            className={`period-button cursor-pointer ${period === Period.YEAR ? 'blue' : 'white-12'}`}
                            onClick={() => setPeriodOnClick(Period.YEAR)}>{t('year')}
                        </button>
                        : null
                    }
                </div>
            </div>

            <div className={'mb-10'}>
                <div className={'section-title'}>{t('pages')}:</div>
                <div style={{marginTop: '5px', display: 'flex', flexWrap: 'nowrap', overflowX: 'auto'}}>
                    {[1,2,3,4,5,6,7,8,9,10].map((item, index) =>
                        <button key={index + 1} className={`period-button cursor-pointer ${currentPage === item ? 'blue' : 'white-12'} ${appType == AppType.DYOR ? 'opacity-05' : 'opacity-1'}`} onClick={() => setPage(item)}>{RagePage[item].start}-{RagePage[item].end}</button>
                    )}
                </div>
            </div>

            <div>
                <div className={'section-title'}>{t('app_info')}:</div>
                <div className={'content-settings-section-earn-text'}>platform = {tg.platform}</div>
                <div className={'content-settings-section-earn-text'}>telegram id = {userTelegramId ?? "unknown"}</div>
                <div className={'content-settings-section-earn-text'}>telegram name = {username ?? "unknown"}</div>
                <div className={'content-settings-section-earn-text'}>web app = {Constants.cbAppVersion}</div>
                <div className={'content-settings-section-earn-text'}>start param = {startParam}</div>
                <div className={'content-settings-section-earn-text'}>onInitReferResponseError = {onInitReferResponseError}</div>
            </div>
        </div>
    );
};
export default ContentSettingsPage;
