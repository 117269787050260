import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Constants from "../../../constants/constants";
import BottomNavBarComponent from "../../../component/bottom-nav-bar/bottom-nav-bar.component";
import LoadingComponent from "../../../component/loading/loading.component";
import ActivityService from "../../../service/activity.service";
import './activities.page.css';
import moment from "moment";

// const tg = window.Telegram.WebApp;

const ActivitiesPage = () => {
    const userTelegramId = Constants.getTelegramUserId();//tg.initDataUnsafe.user?.id; // 226875807
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [activitiesData, setActivitiesData] = useState(null);
    const [filteredActivitiesData, setFilteredActivitiesData] = useState(null);
    const [activitiesDataIsLoading, setActivitiesDataIsLoading] = useState(false);
    const [activitiesDataError, setActivitiesDataError] = useState(null);

    const [activitiesType, setActivitiesType] = useState(null);


    useEffect(() => {
        getActivitiesData();
    }, []);

    useEffect(() => {
        if (activitiesData == null) {
            return
        }
        switch (activitiesType) {
            case 'ONE_TIME':
                setFilteredActivitiesData({...filteredActivitiesData, activities: activitiesData.activities.filter((e) => !e.isMultiple)});
                break;
            case 'MULTIPLE':
                setFilteredActivitiesData({...filteredActivitiesData, activities: activitiesData.activities.filter((e) => e.isMultiple)});
                break;
            default:
                setFilteredActivitiesData(activitiesData);
                break;
        }
    }, [activitiesData, activitiesType])

    async function getActivitiesData() {
        if (userTelegramId == null) {
            setActivitiesDataError(t('work_only_in_telegram_version'));
            return;
        }
        try {
            setActivitiesDataError(null);
            setActivitiesDataIsLoading(true);

            const response = await ActivityService.getActivities(userTelegramId);
            if (response.success) {
                setActivitiesData({
                    activitiesCount: response.activitiesCount,
                    activities: response.activities,
                })
            } else {
                setActivitiesDataError(response.error);
            }
        } catch (e) {
            setActivitiesDataError(e.message);
        }
        setActivitiesDataIsLoading(false);
    }

    function changeActivityType(activityType) {
        if (activitiesDataIsLoading) {
            return;
        }
        setActivitiesType(activityType)
    }

    async function onItemClick(item) {
        console.log(item)
        navigate(`/activities/${item.id}`)
    }

    function isCompletedMultipleActivityToday(activity) {
        if (activity.isMultiple && activity.dates.length > 0) {
            const dateNow = moment(new Date());
            const lastActivityDate = new Date(parseInt(activity.dates[activity.dates.length-1]));
            const isToday = dateNow.diff(lastActivityDate,"d") <= 0;
            return isToday;
        }
    }

    return (
        <div className={'page'}>
            <div className={'page-container'}>
                <div className={'mb-10'}>
                    <div className={'title mb-5'}>{t('activities')}:</div>
                    <LoadingComponent isLoading={activitiesDataIsLoading} error={activitiesDataError}/>

                    {activitiesData != null && filteredActivitiesData != null
                        ? <>

                            <div style={{display: 'flex'}}>
                                <div className={'mb-10'} style={{
                                    backgroundColor: '#444444e6',
                                    fontSize: '12px',
                                    borderRadius: '5px',
                                    padding: '2px 10px'
                                }}>{t('activities_new_tip')}<a href={'https://t.me/cz01_eth'} style={{color: '#07d'}} target={'_blank'}> (support)</a></div>
                            </div>


                            <div className={'mb-10'}>
                                <div style={{marginTop: '5px', display: 'flex'}}>
                                    <button style={{padding: '5px 15px'}} className={`period-button cursor-pointer ${activitiesType == null ? 'blue' : 'white-12'}`} onClick={() => changeActivityType(null)}>{t('all')}</button>
                                    <button style={{padding: '5px 15px'}} className={`period-button cursor-pointer ${activitiesType == 'ONE_TIME' ? 'blue' : 'white-12'}`} onClick={() => changeActivityType('ONE_TIME')}>{t('one_time')}</button>
                                    <button style={{padding: '5px 15px'}} className={`period-button cursor-pointer ${activitiesType == 'MULTIPLE' ? 'blue' : 'white-12'}`} onClick={() => changeActivityType('MULTIPLE')}>{t('multiple')}</button>
                                </div>
                            </div>

                            {/*<div className={'mb-10'}>{t('frens')} ({frensData.referralsCount}):</div>*/}
                            {filteredActivitiesData.activities.map((item, index) =>
                                <div key={index + 1} className={'activities-item'} style={{opacity: item.isCompleted ? '0.3' : '1'}} onClick={() => onItemClick(item)}>
                                    <div style={{
                                        display: 'flex',
                                    }}>
                                        <div className={'activities-item-type'}>{item.isMultiple ? 'daily' : 'one time'},</div>
                                        <div className={'activities-item-type'}>{item.type.toLowerCase()}</div>
                                    </div>
                                    <div className={'activities-item-card'}>
                                        {/*<div className={'activities-item-number'}/>*/}
                                        <div style={{
                                            textDecoration: item.isCompleted ? 'line-through': 'none'
                                        }}>{item.title}</div>
                                        {/*{item.isCompleted*/}
                                        {/*    ? <div className={'earn-item-button'} style={{opacity: '0.1'}}>✓</div>*/}
                                        {/*    : null*/}
                                        {/*}*/}
                                    </div>
                                    {isCompletedMultipleActivityToday(item) ?
                                        <div style={{marginTop: '5px', fontSize: '12px', color: '#00ff22'}}>{t('activity_today_is_completed')}</div>
                                        : null
                                    }
                                </div>
                            )}
                        </>
                        : null
                    }
                </div>
            </div>

            <BottomNavBarComponent activeLink={2}/>
        </div>
    );
};

export default ActivitiesPage;
