import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import './search-coin.css';

const SearchCoinPage = ({
                            coinData,
                            setVisible,
                            onTappedCoin,
                        }) => {
    const { t } = useTranslation();

    const [coinList, setCoinList] = useState([]);
    // const [coinListIsLoading, setCoinListIsLoading] = useState(false);
    // const [coinListError, setCoinListError] = useState(null);

    useEffect(() => {
        setCoinList(coinData)
    }, [])

    const onInputChange = (event) => {
        const q = event.target.value.toLowerCase().trim();

        if (q === "") {
            setCoinList(coinData)
            return;
        }

        const filteredCoinList = coinData.filter((e) => e.name.toLowerCase().includes(q) || e.symbol.toLowerCase().includes(q))
        setCoinList(filteredCoinList)
    }

    return (
        <div style={{
            padding: '10px'
        }}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center'}}>
                <div style={{display: 'flex'}}>
                    {/*<div style={{marginLeft: '10px'}}><span>{coinData.symbol}</span></div>*/}
                </div>
                <button className={'modal-close'} onClick={() => setVisible(false)}>
                    <svg style={{height: '24px', fill: 'white'}} viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </svg>
                </button>
            </div>

            <div className={'mb-10'}>
                <div className={'section-title'}>{t('search')}:</div>
                <div style={{marginTop: '5px', marginBottom: '10px'}}>
                    <input className={'search-coin-input'} placeholder={t('cryptocurrency')} onChange={onInputChange}/>
                </div>

                <div>
                    {coinList.map((item, index) =>
                        <div
                            key={index + 1}
                            className={'search-coin-element'}
                            onClick={() => {onTappedCoin(item)}}
                        >
                            <div className={'search-coin-element-logo'}>
                                {/*<img className={'search-coin-element-logo-img'} src={item.image}/>*/}
                            </div>
                            <div>{item.name}</div>
                        </div>
                    )}

                </div>
            </div>

        </div>
    );
};
export default SearchCoinPage;
