import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Markdown from 'react-markdown'
import Constants from "../../../constants/constants";
import BottomNavBarComponent from "../../../component/bottom-nav-bar/bottom-nav-bar.component";
import LoadingComponent from "../../../component/loading/loading.component";
import ActivityService from "../../../service/activity.service";
import './activity.page.css';
import moment from "moment";

// const tg = window.Telegram.WebApp;

const ActivityPage = () => {
    const userTelegramId = Constants.getTelegramUserId();//tg.initDataUnsafe.user?.id; // 226875807
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {activityId} = useParams();

    const [activityData, setActivityData] = useState(null);
    const [activityDataIsLoading, setActivityDataIsLoading] = useState(false);
    const [activityDataError, setActivityDataError] = useState(null);

    const [completeActivityIsLoading, setCompleteActivityIsLoading] = useState(false);
    const [completeActivityError, setCompleteActivityError] = useState(null);

    useEffect(() => {
        getActivityData();

        var BackButton = window.Telegram.WebApp.BackButton;
        BackButton.show();
        BackButton.onClick(function() {
            navigate('/activities');
            BackButton.hide();
        });
        // window.Telegram.onEvent('backButtonClicked', function() {
        //     console.
        // });

    }, []);

    async function getActivityData() {
        if (userTelegramId == null) {
            setActivityDataError(t('work_only_in_telegram_version'));
            return;
        } else if (activityId == null) {
            setActivityDataError('Miss activity id');
            return;
        }
        try {
            setActivityDataIsLoading(true);
            const response = await ActivityService.getActivity(userTelegramId, activityId);
            if (response.success) {
                setActivityData(response.activity)
            } else {
                setActivityDataError(response.error);
            }
        } catch (e) {
            setActivityDataError(e.message);
        }
        setActivityDataIsLoading(false);
    }

    function isCompletedMultipleActivityToday() {
        if (activityData.isMultiple && activityData.dates.length > 0) {
            const dateNow = moment(new Date());
            const lastActivityDate = new Date(parseInt(activityData.dates[activityData.dates.length-1]));
            const isToday = dateNow.diff(lastActivityDate,"d") <= 0;
            return isToday;
        }
    }

    function isCompletedActivity() {
        if (!activityData.isMultiple) {
            return activityData.isCompleted;
        }

        // const dateNow = moment(new Date());
        // const lastActivityDate = new Date(parseInt(activityData.dates[activityData.dates-1]));
        //
        // const isToday = dateNow.diff(lastActivityDate,"d") <= 0;
        // return isToday;
    }

    async function onCompletedTapped() {
        if (activityId == null) {
            setCompleteActivityError('Miss activity id');
            return;
        }
        try {
            setCompleteActivityError(null);
            setCompleteActivityIsLoading(true);
            const response = await ActivityService.completeActivity(userTelegramId, activityId);
            if (response.success) {
                const dates = activityData.dates;
                dates.push(Date.now());
                setActivityData({...activityData, isCompleted: true, dates: dates})
            } else {
                setCompleteActivityError(response.error);
            }
        } catch (e) {
            setCompleteActivityError(e.message);
        }
        setCompleteActivityIsLoading(false);
    }

    function getRequirementValue(requirement) {
        switch (requirement) {
            case "TWITTER":
                return <div>{t('activity_requirements_twitter')}</div>;
            case "WALLET":
                return <div>{t('activity_requirements_wallet_mm')}</div>;
            case "GALXE":
                return <div>{t('activity_requirements_galxe')} <a style={{color: '#07d'}} href={'http://vk.com'} target={'_blank'}>(инструкция по созданию)</a></div>;
            default:
                return requirement;
        }
    }

    function formatDate(element) {

        const date = new Date(Number(element));
        const months = ['01','02','03','04','05','06','07','08','09','10','11','12'];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = "0" + date.getMinutes();

        return `${day}.${month}.${year} ${hours}:${minutes.substr(-2)}`;
    }

    return (
        <div className={'page'}>
            <div className={'page-container'}>
                <div className={'mb-10'}>
                    <LoadingComponent isLoading={activityDataIsLoading} error={activityDataError}/>

                    {activityData != null ?
                        <>
                            {isCompletedMultipleActivityToday() ?
                                <div style={{display: 'flex'}}>
                                    <div className={'mb-10'} style={{
                                        backgroundColor: '#444444e6',
                                        fontSize: '12px',
                                        borderRadius: '5px',
                                        padding: '2px 10px',
                                        color: '#00ff22'
                                    }}>{activityData.isMultiple ? t('activity_today_is_completed') : t('completed')}</div>
                                </div>
                                : null
                            }

                            <div className={'title mb-5'} style={{
                                textDecoration: isCompletedActivity() ? 'line-through' : '',
                                opacity: isCompletedActivity() ? '0.5' : '1',
                            }}>{activityData.title}:</div>

                            <div>{t('activity_requirements')}</div>
                            <div className={'activity-section-requirements'}>
                                {activityData.requirements.map((item, index) =>
                                    <div key={index + 1} className={'activity-requirements'}>
                                        {getRequirementValue(item)}
                                    </div>
                                )}
                            </div>

                            {activityData.instruction ?
                                <div className={'activity-section'}>
                                    <div>{t('activity_instruction')}:</div>
                                    <div className={'activity-section-content activity-steps'} style={{width: 'fit-content'}}>
                                        {activityData.instruction}
                                    </div>
                                </div>
                                : null
                            }

                            <div className={'activity-section'}>
                                <div>{t('steps')}:</div>
                                <div className={'activity-section-content'}>
                                    {activityData.steps.map((item, index) =>
                                        <div key={index + 100} className={'activity-steps'}>
                                            <Markdown
                                                components={{
                                                    a: ({
                                                            node, href, children, ...props
                                                        }) => <a href={href} target="_blank" {...props}>{children}</a>,
                                                }}
                                            >{item}</Markdown>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div>{activityData.isMultiple ? t('dates') : t('date')}:</div>
                            {activityData.dates.length > 0 ?
                                <div>
                                    {activityData.dates?.map((item, index) =>
                                        <div key={index + 1000}>{formatDate(item)},</div>
                                    )}
                                </div>
                                : <div>-</div>
                            }

                        </>
                        : null
                    }

                </div>
            </div>

            <BottomNavBarComponent activeLink={2} child={
                completeActivityIsLoading ?
                    <div className={'navbar-button-container'}>
                        Loading...
                    </div>
                    :
                activityData != null && !isCompletedActivity() ?
                    <div className={'navbar-button-container'}>
                        <button style={{width: '100%'}} className={`period-button cursor-pointer blue`} onClick={onCompletedTapped}>{t('completed')}</button>
                        {completeActivityError ?
                            <div className={'error'}>{completeActivityError}</div>
                            : null
                        }
                    </div>
                : null
            }/>
        </div>
    );
};

export default ActivityPage;
