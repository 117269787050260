import {Constants} from "../constants/constants";

class ActivityService {
    static async getActivities(userTelegramId) {
        try {
            const jsonData = {
                "userTelegramId": userTelegramId
            };
            const response = await fetch(Constants.baseUrl + `/api/v1/activities/getAll`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(jsonData)
                }
            );
            return await response.json();
            // return {
            //     success: true,
            //     activitiesCount: 10,
            //     activities: [
            //         {
            //             "id": 1,
            //             "title": "Отработака хомяка",
            //             "type": "TG",
            //             "isMultiple": false,
            //             "isCompleted": false,
            //         },
            //         {
            //             "id": 2,
            //             "title": "Активность от абстракта",
            //             "type": "GALXE",
            //             "isMultiple": true,
            //             "isCompleted": true,
            //         },
            //         {
            //             "id": 3,
            //             "title": "Потанцеальный дроп от Pudgy Penguins с длинным названием",
            //             "type": "GALXE",
            //             "isMultiple": true,
            //             "isCompleted": false,
            //         },
            //         {
            //             "id": 4,
            //             "title": "Потанцеальный дроп от Pudgy Penguins",
            //             "type": "LAYER3",
            //             "isMultiple": true,
            //             "isCompleted": true,
            //         },
            //     ]
            // };
        } catch (e) {
            throw e;
        }
    }

    static async getActivity(userTelegramId, activityId) {
        try {
            const jsonData = {
                "userTelegramId": userTelegramId,
                "activityId": activityId
            };
            const response = await fetch(Constants.baseUrl + `/api/v1/activities/getOne`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(jsonData)
                }
            );
            return await response.json();
            // return {
            //     success: true,
            //     activity: {
            //         "id": 2,
            //         "title": "Активность от абстракта",
            //         "type": "GALXE",
            //         "isMultiple": true,
            //         "isCompleted": false,
            //         "requirements": [
            //             "TWITTER", "WALLET", "GALXE"
            //         ],
            //         "steps": [
            //             "Перейти на сайт [Galxe](https://app.galxe.com/quest/Abstract/GCSeEtKJcR)",
            //             "Ответы на квесты: A, C, D, A",
            //         ]
            //     },
            // };
        } catch (e) {
            throw e;
        }
    }

    static async completeActivity(userTelegramId, activityId) {
        try {
            const jsonData = {
                "userTelegramId": userTelegramId,
                "activityId": activityId
            };
            const response = await fetch(Constants.baseUrl + `/api/v1/activities/completed`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(jsonData)
                }
            );
            return await response.json();
            // return {success: true};
        } catch (e) {
            throw e;
        }
    }
}
export default ActivityService;
