import React from 'react';
import { useNavigate } from "react-router-dom";
import {NavLink} from "react-router-dom";
import './bottom-nav-bar.css'
import { useTranslation } from "react-i18next";

const BottomNavBarComponent = ({activeLink, child, onSettingsOnClick, onSearchOnClick}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    function settingsButtonTapped() {
        if (activeLink === 1) {
            onSettingsOnClick();
        } else {
            navigate('/')
        }
    }

    function searchButtonTapped() {
        if (activeLink === 1) {
            onSearchOnClick();
        } else {
            navigate('/')
        }
    }

    return (
        <div className={'bottom-nav-bar'}>
            {child}
            {activeLink === 1
                ? <div className={'setting-button-container'}>
                    <button
                        className={`setting-button`}
                        style={{
                            opacity: activeLink === 1 ? '1' : '0.5',
                            marginRight: '5px',
                        }}
                        onClick={searchButtonTapped}
                    >
                        {/*{RagePage[currentPage].start}-{RagePage[currentPage].end}*/}
                        {/*<svg style={{fill: 'currentColor', pointerEvents: 'none', height: '24px'}} viewBox="0 -960 960 960">*/}
                        {/*    <path d="M120-200v-80h720v80H120Zm0-160v-80h720v80H120Zm0-160v-80h720v80H120Zm0-160v-80h720v80H120Z"></path>*/}
                        {/*</svg>*/}
                        <svg style={{fill: 'currentColor', pointerEvents: 'none', height: '24px'}} viewBox="0 0 24 24">
                            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                        </svg>
                    </button>
                    <button
                        className={`setting-button`}
                        style={{
                            opacity: activeLink === 1 ? '1' : '0.5',
                        }}
                        onClick={settingsButtonTapped}
                    >
                        {/*{RagePage[currentPage].start}-{RagePage[currentPage].end}*/}
                        {/*<svg style={{fill: 'currentColor', pointerEvents: 'none', height: '24px'}} viewBox="0 -960 960 960">*/}
                        {/*    <path d="M120-200v-80h720v80H120Zm0-160v-80h720v80H120Zm0-160v-80h720v80H120Zm0-160v-80h720v80H120Z"></path>*/}
                        {/*</svg>*/}
                        <svg style={{fill: 'currentColor', pointerEvents: 'none', height: '24px'}} viewBox="0 0 24 24">
                            <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"></path>
                        </svg>
                    </button>
                </div>
                : null
            }
            <div style={{height: '1px', width: '100%', backgroundColor: 'gray'}}/>
            <div style={{
                // display: 'flex',
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    width: '100%',
                    backgroundColor: '#222'
                }}>
                    <NavLink className={`bottom-nav-bar-button ${activeLink === 1 ? 'bottom-nav-bar-button-active' : ''}`} to={"/"}>{t('home')}</NavLink>
                    <NavLink className={`bottom-nav-bar-button ${activeLink === 2 ? 'bottom-nav-bar-button-active' : ''}`} to={"/activities"}>{t('activities')}</NavLink>
                    <NavLink className={`bottom-nav-bar-button ${activeLink === 3 ? 'bottom-nav-bar-button-active' : ''}`} to={"/earn"}>{t('earn')}</NavLink>
                    <NavLink className={`bottom-nav-bar-button ${activeLink === 4 ? 'bottom-nav-bar-button-active' : ''}`} to={"/frens"}>{t('frens')}</NavLink>
                </div>
            </div>
        </div>
    );
};

export default BottomNavBarComponent;
